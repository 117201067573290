// page structure
body {
  background-color: #0c1c3c;
  color: white;
  font-size: 1.25rem;
}
.grid-container.holder {
  padding: 0;
}
header {
  background-color: #343850;
  padding: 10px
}
.menu {
  margin: 0px 0px -15px;
  font-size: 1.1rem;
  a {
    color: white;
    &:hover {
      color: #e8957f;
    }
  }
}
footer {
  padding: 20px
}
footer p {
  font-size: 1em;
  line-height: 170%;
  a {
    color: white;
  }
}
#ContactBar {
  background-color: #dde2f3;
  padding: 2% 10%;
  p {
    color: #0c1c3c;
    // font-family: $body-header-family;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.3em;
    margin: 0;
  }
  a {
    color: inherit;
  }
}
form {
  label {
    color: white;
    font-size: 1em;
  }
}

// home page image panels
#Hero {
  background-image: url("../img/lavender-1668063_1920.jpg");
  .main-panel-padding {
    padding: 10% 18% 11%
  }
  h3, h4 {
    color: white;
  }
}
#Aromatherapy {
  background-image: url("../img/essential-oils-3931430_1920.jpg");
  .main-panel-padding {
    padding: 12% 20%
  }
}
#Reiki {
  background-image: url("../img/precious-1432335_1920.jpg");
  .main-panel-padding {
    padding: 12% 20%
  }
}
#Aromatherapy, #Reiki {
  p {
    font-family: $body-header-family;
    font-size: 1.5em;
  }
}
.fill-bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.overlay-bg {
  background-color: rgba(31, 34, 48, 0.7);
}
// hero image panels
.aboutPage #ImagePanel {
  background-image:  url("../img/natural-cosmetics-4004909_1920.jpg") ;
}
.aromatherapyPage #ImagePanel {
  background-image:  url("../img/bottles.jpg") ;
}
.reikiPage #ImagePanel {
  background-image:  url("../img/precious-1432335_1920.jpg") ;
}
.treatmentsPage #ImagePanel {
  background-image:  url("../img/IMG_0225_edited_edited_edited.jpg") ;
}
.contactPage #ImagePanel {
  background-image:  url("../img/bottles-1433611_1920.jpg") ;
}
// main text panels
#ImagePanel, #TextPanel {
  min-height: 300px;
  .main-panel-padding {
    padding: 10% 15%
  }
}
#TextPanel {
  h4 {color: $primary-color;}
}
.full-width {
  #TextPanel {
    .main-panel-padding {
      padding: 2.5% 15%
    }
  }
}

// tools
.align-right {text-align: right;}
.block {display: block;}
.margin-top-10 {margin-top: rem-calc(10);}
.margin-top-20 {margin-top: rem-calc(20);}
.margin-top-30 {margin-top: rem-calc(30);}
.margin-top-40 {margin-top: rem-calc(40);}
